import { IconButton, withStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import React, { Component } from 'react';
import api from '../../api/api';
import { manager } from './../../App';
import * as I from './../../api/interfaces';
import SuperDrawer from './DrawerContainer';

const styles = (theme: Theme) => ({
    menuButton: {
        marginRight: 0,
    },
}) as any;

export interface CategoriesExtended extends I.Category {
    categories: CategoriesExtended[],
    amount: number,
    markers: I.ExtendedMarker[]
}

class CategoryDrawer extends Component<any, { categories: I.Category[], allowed: number[], showDLC: boolean, openDesktopDrawer: boolean }> {
    state = {
        categories: manager.categories,
        allowed: manager.filter.categories,
        showDLC: true,
        openDesktopDrawer: true
    }

    componentDidMount() {

        manager.onChange(() => {
            this.setState({ categories: manager.categories, allowed: manager.filter.categories });
        })

        api.categories.get((categories: any) => {
            if (!manager.categories.length) manager.setCategories(categories);
        });
    }

    toggleCategory = (id: number) => {
        let allowed = manager.filter.categories;
        if (manager.filter.categories.includes(id)) {
            allowed = allowed.filter(categoryId => categoryId !== id);
        } else {
            allowed.push(id);
        }
        const updatedCategory = this.state.categories.find(category => category.id === id);
        if (updatedCategory) {
            if (updatedCategory.parent && !allowed.includes(id)) {
                allowed = allowed.filter(categoryId => categoryId !== updatedCategory.parent);
            }
            if (!updatedCategory.parent) {
                if (allowed.includes(id)) {
                    const toAdd = this.state.categories.filter(category => category.parent === id && !allowed.includes(category.id)).map(cat => cat.id);
                    allowed.push(...toAdd);
                } else {
                    const children = this.state.categories.filter(category => category.parent === id).map(cat => cat.id);
                    allowed = allowed.filter(categoryId => !children.includes(categoryId));
                }
            }
        }
        manager.updateFilter('categories', allowed);
    }

    toggleDLC = () => {
        this.setState({ showDLC: !this.state.showDLC }, () => {
            manager.updateFilter('showDLC', this.state.showDLC);
        });
    }

    mapSubcategories = (category: I.Category) => {
        const markers = manager.markers.filter(marker => marker.category === category.id)
        return {
            ...category,
            categories: [],
            markers,
            amount: markers.length
        }
    }

    setNewOpenState = (open: boolean) => {
        this.setState({openDesktopDrawer: open});
    }

    render() {
        if (!this.props.show) {
            return null;
        }
        const { categories } = this.state
        const superList: CategoriesExtended[] = [];
        for (const category of categories) {
            if (category.parent) continue;
            const children = categories.filter(subcategory => subcategory.parent === category.id).map(this.mapSubcategories);
            superList.push({
                ...category,
                amount: children.length,
                categories: children,
                markers: []
            });

        }
        return (
            <>
                <div id="desktop-drawer-container">
                    <SuperDrawer
                        cxt={this.props.cxt}
                        list={superList}
                        allowed={this.state.allowed}
                        toggleCategory={this.toggleCategory}
                        onOpenStateChanged={this.setNewOpenState}>
                            <IconButton className={this.props.classes.menuButton} id="hamburger_button_desktop" color="inherit" aria-label="Menu">
                            {
                                this.state.openDesktopDrawer ? <ArrowBackIos /> : <ArrowForwardIos /> 
                            }
                            </IconButton>
                        </SuperDrawer>
                </div>
                <SuperDrawer
                    list={superList}
                    cxt={this.props.cxt}
                    allowed={this.state.allowed}
                    toggleCategory={this.toggleCategory}
                    mobile={true}
                >
                    {this.props.children}
                </SuperDrawer>
            </>
        )
    }
}

export default withStyles(styles)(CategoryDrawer);