import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import React, { CSSProperties } from "react";
import api from "../../api/api";
import { IUserContext } from "../modules/Context";
const styless: {[key: string]: CSSProperties} = {
    redButton: {
        color: 'red',
    },
}

interface IProps {
    cxt: IUserContext,
}

interface IState {
    open: boolean,
}


export default class TermsOfServiceModal extends React.Component<IProps, IState> {
    acceptTOS = () => {
        return api.tos.accept(() => {
            if (this.props.cxt.user) {
                this.props.cxt.setTOSAccepted();
            }
        });
    }

    logout = () => {
        this.props.cxt.logout();
    }

    render() {
        return (
			<>
				<Dialog open={(this.props.cxt.user && !this.props.cxt.user.hasAcceptedTOS) === true} aria-labelledby="form-dialog-title">
					<DialogTitle id="form-dialog-title">New Terms of Service and Privacy Policy</DialogTitle>
					<DialogContent>
						<a href="/TermsandCondition.pdf" target="_blank">
							Terms of Service
						</a>{' '}
						and{' '}
						<a href="/PrivacyPolicy.pdf" target="_blank">
							Privacy Policy
						</a>{' '}
						have been updated. You need to accept them to continue using this site.
					</DialogContent>
					<DialogActions>
						<Button onClick={this.logout} color="primary" style={styless.redButton}>
							Log out
						</Button>

						<Button onClick={this.acceptTOS} color="primary">
							Accept
						</Button>
					</DialogActions>
				</Dialog>
			</>
		);
    }
}