
import React from 'react';
import { Circles } from 'react-loader-spinner';



export default class LoaderSpinner extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
    }

    render() {
        return <Circles
          height = "80"
          width = "80"
          color = 'green'
          ariaLabel = 'three-dots-loading'
        />
    }
}