import React from "react";

export const genericButton: React.CSSProperties = {
  border: 'none',
  background: '#54f2f2',
  height: '54px',
  borderRadius: '26px',
  fontSize: '16px',
  color: 'white',
  marginBottom: '10px',
  marginTop: '10px'
};