import { IconButton, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import api from '../../api/api';
import { ExtendedMarker } from '../../api/interfaces';
import Delete from './../styles/delete_button.png';

export default class DeleteMarkerButton extends React.Component<{ marker: ExtendedMarker, callback: any }> {
    state = {
        open: false,
        description: ''
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleChange = (e: any) => {
        const { state } = this;
        state.description = e.target.value;
        this.setState(state);
    }

    deleteMarker = () => {
        api.markers.delete(this.props.marker.id, this.props.callback);
    }

    render() {
        return (
            <>
                <Tooltip title="Delete your private marker">
                    <IconButton style={{ padding: '8px' }}>
                        <img src={Delete} onClick={this.handleClickOpen} />
                    </IconButton> 
                </Tooltip>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">Delete marker</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete this marker?
                            <Typography variant="h5" component="h3" style={{textAlign: 'center'}}>
                                {this.props.marker.name}
                            </Typography>
                            <Typography variant="h6" component="h6" style={{textAlign: 'center'}}>
                                {this.props.marker.description}
                            </Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.deleteMarker} color="primary">
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </>)
    }
}