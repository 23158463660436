import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import api from '../../api/api';

export default class FormDialog extends React.Component<{ markerId: number, callback: any, marker: any }> {
    state = {
        open: false,
        description: ''
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleChange = (e: any) => {
        const { state } = this;
        state.description = e.target.value;
        this.setState(state);
    }

    acceptMarker = () => {
        api.markers.update(this.props.markerId, { ...this.props.marker, accepted: true }, this.props.callback);
    }

    render() {
        return (
            <>
                <Tooltip title="Use your admin powers to accept this marker">
                    <div onClick={this.handleClickOpen} className="clickable accept-marker">
                        Accept Marker
                    </div>
                </Tooltip>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">Accept this marker</DialogTitle>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.acceptMarker} color="primary">
                            Accept
                        </Button>
                    </DialogActions>
                </Dialog>
            </>)
    }
}