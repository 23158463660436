import React, { Component } from 'react';
import { Paper, Typography, TextField, Button } from '@material-ui/core';
import { IUserContext } from '../Context';
import api from '../../../api/api';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { manager } from './../../../App';
import logo from './../../styles/title.png';
import config from '../../../api/config';

const styles = {
    root: {
        width: '90%',
        maxWidth: '500px',
        alignSelf: 'center',
        padding: '15pt',
        boxShadow: 'none',
        border: 'none',
        background: 'none',
    },
    
    input: {
        borderRadius: '14px',
    },
    button: {
        border: 'none',
        background: '#54f2f2',
        height: '54px',
        borderRadius: '26px',
        fontSize: '16px',
        color: 'white',
        marginTop: '20px',
        fontFamily: 'Poppins',
        fontWeight:500
    },
    title: {
        marginBottom: '60px'
    },
    container: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'center'
    },
    subtitle: {
        fontWeight: 600,
        color: 'white',
        fontSize: '40px',
        textAlign: 'center' as 'center',
    }
}
export default class RememberPassword extends Component<any, { form: any, done: boolean, errors: string[] }> {
    constructor(props: { cxt: IUserContext }) {
        super(props);
        this.state = {
            form: {
                password: '',
                password2: '',
                token: ''
            },
            done: false,
            errors: []
        }
    }

    componentDidMount() {
        if (this.props.match && this.props.match.params.token) {
            const { form } = this.state;
            form.token = this.props.match.params.token;
            this.setState({ form });
        }
        manager.toggle(false);
    }


    handleKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            this.save();
        }
    }

    handleChange = (e: any) => {
        const { form } = this.state;
        form[e.target.name] = e.target.value;
        this.setState({ form });
    }

    save = () => {
        let errors = [];
        if (!this.state.form.password.length) errors.push("Password is required");
        if (this.state.form.password !== this.state.form.password2) errors.push("Password must be the same");


        if (!errors.length) {
            api.user.savePassword(this.state.form.token, this.state.form.password, (res: any) => {
                this.setState({ done: true })

            }, (err: any) => {
                console.log(err)
            })
        }
        return this.setState({ errors });
    }

    render() {
        if (!this.props.match || !this.props.match.params.token) {
            return <Redirect to={'/'} />
        }
        return (
            <div style={styles.container}>
                <Link to='/' className="logo-container"><img src={logo} className='logo-login' /></Link>
                <Typography variant="h5" component="h1" className="interactive-map-title" style={styles.title}>
                {config.headerTitle}
                <div className="beta-container">Beta</div>
            </Typography>
                <Paper style={styles.root}>
                    <Typography variant="h5" component="h3" style={styles.subtitle}>
                        Reset your passwords
                </Typography>
                    {!this.state.done ? <div className="form-container">
                        {this.state.errors.length ? this.state.errors.map(error => <Typography key={error} variant='body1' component='p'>{error}</Typography>) : ''}

                        <TextField
                            label="Enter your new password"
                            name='password'
                            type='password'
                            value={this.state.form.password}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                            onKeyPress={this.handleKeyPress}
                            style={styles.input}
                            inputProps={{
                                style: {
                                    color: 'white'
                                }
                            }}
                            InputProps={{
                                classes: {
                                    notchedOutline: 'input-login'
                                }
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: 'input-login-label'
                                }
                            }}
                        />
                        <TextField
                            label="Re-Enter your new password"
                            name='password2'
                            type='password'
                            value={this.state.form.password2}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                            onKeyPress={this.handleKeyPress}
                            style={styles.input}
                            inputProps={{
                                style: {
                                    color: 'white'
                                }
                            }}
                            InputProps={{
                                classes: {
                                    notchedOutline: 'input-login'
                                }
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: 'input-login-label'
                                }
                            }}
                        />
                        <Button variant="outlined" onClick={this.save} style={styles.button} >
                            Save
                    </Button>
                    </div> : <Typography variant='body1' component='p'>
                            Your new password has been saved!
                    </Typography>}
                </Paper>
            </div>
        )
    }
}