import { Button, Paper, TextField, Typography } from '@material-ui/core';
import React, { Component } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from 'react-router-dom';
import api from '../../../api/api';
import config from '../../../api/config';
import { genericButton } from '../../elements/GenericStyles';
import { manager } from './../../../App';
import logo from './../../styles/title.png';
import { IUserContext } from './../Context';
const styles = {
    root: {
        width: '90%',
        maxWidth: '450px',
        alignSelf: 'center',
        padding: '15pt',
        boxShadow: 'none',
        border: 'none',
        background: 'none',
    },

    input: {
        borderRadius: '14px',
        marginBottom: '30px'
    },
    title: {
        marginBottom: '60px'
    },
    container: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'center'
    },
    subtitle: {
        fontWeight: 600,
        color: 'white',
        fontSize: '40px',
        textAlign: 'center' as 'center',
    }
}
export default class ForgotPassword extends Component<any, { form: any, done: boolean, errors: string[] }> {
    constructor(props: { cxt: IUserContext }) {
        super(props);
        this.state = {
            form: {
                email: '',
                captcha: ''
            },
            done: false,
            errors: []
        }
    }


    handleKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            this.reset();
        }
    }

    handleChange = (e: any) => {
        const { form } = this.state;
        form[e.target.name] = e.target.value;
        this.setState({ form });
    }
    handleCaptcha = (value: any) => {
        const { form } = this.state;
        form.captcha = value;
        this.setState({ form });
    }

    reset = () => {
        let errors = [];
        if (!this.state.form.email.length) errors.push("Email is required");


        if (!errors.length) {
            api.user.reset(this.state.form.email, this.state.form.captcha, (res: any) => {
                this.setState({ done: true })

            }, (err: any) => {
                console.log(err)
            })
        }
        return this.setState({ errors });
    }
    componentDidMount() {
        manager.toggle(false);
    }

    render() {
        return (
            <div style={styles.container}>
                <Link to='/' className="logo-container"><img src={logo} className='logo-login' /></Link>
                <Typography variant="h5" component="h1" className="interactive-map-title" style={styles.title}>
                {config.headerTitle}
                <div className="beta-container">Beta</div>
                </Typography>
                <Paper style={styles.root}>
                    <Typography variant="h5" component="h3" style={styles.subtitle}>
                        Reset your password
                    </Typography>
                    {!this.state.done ? <div className="form-container">
                        <TextField
                            label="Email"
                            name='email'
                            type='email'
                            value={this.state.form.email}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                            onKeyPress={this.handleKeyPress}
                            style={styles.input}
                            inputProps={{
                                style: {
                                    color: 'white'
                                }
                            }}
                            InputProps={{
                                classes: {
                                    notchedOutline: 'input-login'
                                }
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: 'input-login-label'
                                }
                            }}
                        />
                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY || ''}
                            onChange={this.handleCaptcha}
                        />
                        <Button variant="outlined" style={genericButton} onClick={this.reset} >
                            Reset
                    </Button>
                    </div> : <p style={{color: 'white'}}>Email with link to reset your password has been sent!</p>}
                </Paper>
            </div>
        )
    }
}