import React, { Fragment } from 'react';
import { BooleanField, BulkDeleteButton, Datagrid, List, Pagination, TextField } from 'react-admin';

const PostPagination = (props: any) => <Pagination rowsPerPageOptions={[50]} {...props}/>

const BulkActionButtons = (props : any) => (<Fragment>
	<BulkDeleteButton {...props}/>
</Fragment>);

const ReportList = (props: any) => (
	<List {...props}
		bulkActionButtons={<BulkActionButtons/>}
		pagination={<PostPagination/>}
		perPage={50}
	>
		<Datagrid rowClick='edit'>
			<TextField source="id" sortable={true}/>
            <TextField source="description" sortable={false}/>
			<TextField source='owner.username' label='User' sortable={false} />
			<TextField source='markerEnt.name' label='Marker Name' sortable={false} />
			<TextField source='markerEnt.id' label='Marker ID' sortable={false} />
			<BooleanField source='open' />
		</Datagrid>
	</List>
);

export default ReportList;
